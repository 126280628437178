<template>
  <div class="pop-main" v-show="show == 1">
    <div class="pop-bg"></div>
    <div class="pop-windows">
      <div class="pop-title">{{ title }}</div>
      <div class="pop-content" v-html="content"></div>
      <div class="pop-option">
        <button class="el-button pop-btn-cancel" @click="cancel(args)">
          {{ cancelText }}
        </button>
        <button class="el-button pop-btn-ok" @click="confirm(args)">
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popup",
  props: {
    title: String,
    content: String,
    args: Object,
    open: {
      type: Number,
      required: 0,
    },
    cancelText: String,
    confirmText: String,
  },
  data() {
    return {
      show: this.open,
    };
  },
  watch: {
    open(val) {
      this.show = val;
    },
  },
  methods: {
    cancel(args) {
      this.$emit("cancel", args);
      this.closeWindow();
    },
    confirm(args) {
      this.$emit("confirm", args);
      this.closeWindow();
    },
    closeWindow() {
      this.show = false;
    },
    openWindow() {
      this.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.pop-hidden {
  display: none;
}
.pop-bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: block;
}

.pop-windows {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 8px;
  z-index: 1000;
  padding: 30px;
  .pop-title {
    font-size: 24px;
    font-weight: 600;
    color: #1e2b4f;
    margin-bottom: 12px;
  }
  .pop-content {
    width: 540px;
    line-height: 20px;
    font-size: 14px;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .pop-option {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-top: 35px;

    .pop-btn-cancel {
      height: 44px;
      background: #ffffff;
      border-radius: 22px;
      border: 1px solid #d2d5dc;
      margin-right: 20px;

      color: #636d88;
    }
    .pop-btn-ok {
      height: 44px;
      background: #6d5efe;
      border-radius: 22px;

      color: #ffffff;
      font-weight: bold;
    }
  }
}
</style>
