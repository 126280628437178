import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.100:7000/express`


// 获取通知
export function getNotice() {
  return http.get(`${resquest}/user/news/getPopup`);
}

// 获取新闻详情
export function getNewsDetailById(params) {
    return http.get(`${resquest}/user/news/getNewsById`,params);
}

// 获取新闻列表
export function getNewsList(data) {
    return http.post(`${resquest}/user/news/getNewsList`,data);
}