import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.100:7000/express`

//获取中国城市的三级列表
export function getChinaCityList() {
  return http.get(`${resquest}/chinaCity/getChinaCityList`);
}
// 用户新增收件人地址
export function addConsigneeAddress(data) {
  return http.post(`${resquest}/userInfo/consignee/addConsigneeAddress`, data);
}
// 用户更新收件人地址
export function updateConsigneeAddress(data) {
  return http.post(`${resquest}/userInfo/consignee/updateConsigneeAddress`, data);
}
// 用户查询所有收件人地址
export function listAllConsigneeAddress(data) {
  return http.post(`${resquest}/userInfo/consignee/listAllConsigneeAddress`, data);
}
// 用户分页查询收件人地址
export function listConsigneeAddress(data) {
  return http.post(`${resquest}/userInfo/consignee/listConsigneeAddress`, data);
}
// 用户删除收件人地址
export function delConsigneeAddress(params) {
  return http.get(`${resquest}/userInfo/consignee/delConsigneeAddress`, params);
}
// 用户获取默认收件人地址
export function defaultConsigneeAddress(params) {
  return http.get(`${resquest}/userInfo/consignee/defaultConsigneeAddress`, params);
}