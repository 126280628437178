<template>
  <!-- 首页 -->
  <div>
    <div class="home">
      <!-- 主要内容区 下单 批量下单 服务列表 -->
      <div class="home-main">
        <!-- 自定义下单 -->
        <div class="main-input-data">
          <!-- 背景图片 -->
          <img
            width="100%"
            class="input-data_bg"
            src="../assets/img/home/home_input_data_bg.png"
          />
          <!-- 下单操作 -->
          <div class="input-data-content">
            <!-- title -->
            <div class="title">GOCN</div>
            <div class="title-dis">国际转运难，GOCN帮你轻松解决</div>
            <!-- icon -->
            <div class="use-course">
              <img
                width="16"
                height="16"
                src="../assets/img/home/icon_course.png"
              />
              <div class="use-cource-text" @click="toTutorials">下单教程</div>
            </div>
            <!-- 输入操作 -->
            <div class="input-div">
              <!-- select选择发出和发往地 -->
              <div class="input-select">
                <el-select
                  v-model="indentData.whereSend"
                  placeholder="请选择"
                  popper-class="my-select"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div class="send-to">发往</div>
                <el-select
                  v-model="indentData.whereReceive"
                  placeholder="请选择"
                  popper-class="my-select"
                  ref="mySelect"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- input 输入订单具体信息 -->
              <div class="input-information">
                <el-tooltip
                  popper-class="my-tooltip"
                  :manual="true"
                  :value="indentCheck.weight == false"
                  content="请输入此项"
                  placement="top"
                >
                  <el-input
                    v-model="indentData.weight"
                    placeholder="重量"
                    @input="limitInput($event, 'weight')"
                    @change="indentCheck.weight = true"
                  ></el-input>
                </el-tooltip>
                <div class="unit">KG</div>
                <el-tooltip
                  popper-class="my-tooltip"
                  :manual="true"
                  :value="indentCheck.length == false"
                  content="请输入此项"
                  placement="top"
                >
                  <el-input
                    v-model="indentData.length"
                    placeholder="长度"
                    @input="limitInput($event, 'length')"
                    @change="indentCheck.length = true"
                  ></el-input>
                </el-tooltip>
                <div class="unit">CM</div>
                <el-tooltip
                  popper-class="my-tooltip"
                  :manual="true"
                  :value="indentCheck.width == false"
                  content="请输入此项"
                  placement="top"
                >
                  <el-input
                    v-model="indentData.width"
                    placeholder="宽度"
                    @input="limitInput($event, 'width')"
                    @change="indentCheck.width = true"
                  ></el-input>
                </el-tooltip>
                <div class="unit">CM</div>
                <el-tooltip
                  popper-class="my-tooltip"
                  :manual="true"
                  :value="indentCheck.height == false"
                  content="请输入此项"
                  placement="top"
                >
                  <el-input
                    v-model="indentData.height"
                    placeholder="高度"
                    @input="limitInput($event, 'height')"
                    @change="indentCheck.height = true"
                  ></el-input>
                </el-tooltip>
                <div class="unit">CM</div>
              </div>
              <!-- 下单操作按钮 -->
              <div class="input-sure-btn-div">
                <div class="get-now posi" @click="getNowOnClick">立即下单</div>
                <div class="more-get posi">
                  <div class="content" @click="bulkOrderClicked">批量下单</div>
                  <!-- <img width="21px" src="../assets/img/home/icon_right.png" /> -->
                  <i class="el-icon-d-arrow-right content-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 快捷下单 -->
        <div class="main-quit-order">
          <!-- 下单操作 -->
          <div class="choose-order-div">
            <!-- title -->
            <div class="choose-orde-title">
              <div class="choose-title">快捷下单</div>
              <div class="choose-title-es">QUICK ORDER</div>
            </div>
            <div class="order-choose">
              <div
                class="order posi"
                v-for="item in quickOrderData"
                :key="item.index"
                @click="quickOrderClick(item)"
              >
                <el-badge :value="'x' + item.count" class="item">
                  <img
                    width="90px"
                    src="../assets/img/home/home_order_icon.png"
                  />
                </el-badge>
                <div class="order-value">{{ item.value }}</div>
                <div class="order-value-es">{{ item.value_es }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 小功能区 -->
        <div class="main-small-fun">
          <!-- 选择功能 -->
          <div class="choose-fun-div">
            <!-- 标题区域 -->
            <div class="choose-fun-title">
              <div class="choose-title">小功能 大用处</div>
              <img
                class="choose-title-es"
                src="../assets/img/home/home_small_fun_title.png"
              />
            </div>
            <!-- 操作区域 -->
            <div class="choose-fun">
              <div class="fun-item posi" @click="FreightQuery">
                <img
                  class="fun-icon"
                  src="../assets/img/home/home_func_icon1.png"
                />
                <div class="fun-name">运费查询</div>
                <div class="fun-name-es">Freight query</div>
              </div>
              <div class="fun-item posi" @click="idCardUpload">
                <img
                  class="fun-icon"
                  src="../assets/img/home/home_func_icon2.png"
                />
                <div class="fun-name">身份证上传</div>
                <div class="fun-name-es">Id card uploading</div>
              </div>
              <div class="fun-item posi" @click="openCommonProblem">
                <img
                  class="fun-icon"
                  src="../assets/img/home/home_func_icon3.png"
                />
                <div class="fun-name">常见问题汇总</div>
                <div class="fun-name-es">FAQ Summary</div>
              </div>
              <div class="fun-item posi" @click="openSku">
                <img
                  class="fun-icon"
                  src="../assets/img/home/home_func_icon4.png"
                />
                <div class="fun-name">可邮寄物品查询</div>
                <div class="fun-name-es">Mail item enquiries</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 联系我们 -->
        <div class="main-contact-us">
          <div class="contact-us-div">
            <!-- 标题 -->
            <div class="contact-us-title">
              <div class="contact-title">联系我们</div>
              <img
                class="contact-title-es"
                src="../assets/img/home/home_contact_us_title.png"
              />
            </div>
            <!-- 显示区域 -->
            <div class="contact-us">
              <div class="contact-show">
                <div class="show-title">联系方式</div>
                <!-- <div class="show-item">
                <div class="item-value">
                  <div class="item-sign"></div>
                  <div class="item-value-content">4008000000</div>
                </div>
                <div class="item-express">客服电话</div>
              </div> -->
                <div class="show-item">
                  <div class="item-value">
                    <div class="item-sign"></div>
                    <div class="item-value-content">info@gocn.co.uk</div>
                  </div>
                  <div class="item-express">咨询邮箱</div>
                </div>
                <div class="show-item">
                  <div class="item-value">
                    <div class="item-sign"></div>
                    <div class="item-value-content">周一至周五9:00-17:00</div>
                  </div>
                  <div class="item-express">工作时间（英国时间）</div>
                </div>
                +
              </div>
              <div class="contact-input">
                <!-- 标题 -->
                <div class="input-title">SEND E-MAIL</div>
                <div class="input-type">
                  <div
                    class="input-type-item-nor posi"
                    :class="
                      isCallSugest
                        ? 'input-type-item-select'
                        : 'input-type-item-nor'
                    "
                    @click="changeCallType(1)"
                  >
                    建议
                  </div>
                  <div
                    class="input-type-item-nor posi"
                    :class="
                      !isCallSugest
                        ? 'input-type-item-select'
                        : 'input-type-item-nor'
                    "
                    @click="changeCallType(2)"
                  >
                    投诉
                  </div>
                </div>
                <!-- 单行输入 名字 -->
                <div class="input-oneline-item">
                  <div class="input-content-name">您的姓名：</div>
                  <el-input v-model="callContent.name"></el-input>
                </div>
                <!-- 单行输入 电话 -->
                <div class="input-oneline-item">
                  <div class="input-content-name">
                    电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：
                  </div>
                  <el-input v-model="callContent.tel"></el-input>
                </div>
                <!-- 单行输入 邮箱 -->
                <div class="input-oneline-item">
                  <div class="input-content-name">
                    邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：
                  </div>
                  <el-input v-model="callContent.email"></el-input>
                </div>
                <!-- 多行输入 咨询内容 -->
                <div class="input-moreline-item">
                  <el-input
                    v-model="callContent.question"
                    placeholder="请输入您要咨询的问题"
                    type="textarea"
                    resize="none"
                  ></el-input>
                </div>
                <!-- 发送按钮 -->
                <div class="input-send-btn posi" @click="sendClicked">发送</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <popup
      :title="notice.title"
      :content="notice.content"
      :open="notice.open"
      cancelText="关闭"
      confirmText="不再提醒"
      @cancel="onPopCancel"
      @confirm="onPopConfirm"
    ></popup>
  </div>
</template>
<script>
import { getChinaCityList } from "@/api/address/consignee/consignee";
import { addComplaintFeedback } from "@/api/user/user";
import { getNotice } from "@/api/news/news";
import popup from "@components/popup.vue";
import { quickLogin, isLogin } from "@/api/login/login";
import { getQueryVariable } from "@/utils/common";

export default {
  name: "Home",
  components: {
    popup,
  },
  data() {
    return {
      //可选择的国家
      options1: [
        {
          value: "UK",
          label: "UK 英国",
        },
      ],
      //可选择的国家
      options2: [
        {
          value: "CN",
          label: "CN 中国大陆",
        },
      ],
      //订单信息
      indentData: {
        //发出地
        whereSend: "UK",
        //接收地
        whereReceive: "CN",
        //重量
        weight: "",
        //长度
        length: "",
        //宽度
        width: "",
        //高度
        height: "",
      },
      //检测订单输入是否合规
      indentCheck: {
        //重量
        weight: true,
        //长度
        length: true,
        //宽度
        width: true,
        //高度
        height: true,
      },
      //快捷下单的数据
      quickOrderData: [
        {
          count: 2,
          value: "两罐奶粉",
          value_es: "Two cans",
        },
        {
          count: 4,
          value: "四罐奶粉",
          value_es: "Four cans",
        },
        {
          count: 6,
          value: "六罐奶粉",
          value_es: "Six cans",
        },
      ],
      //咨询类型选择的是否为建议
      isCallSugest: true,
      //区分建议投诉类型
      callSuggest: 1,
      //咨询的数据内容
      callContent: {
        name: "",
        tel: "",
        email: "",
        question: "",
      },
      notice: {
        title: "",
        content: "",
        open: 0,
      },
    };
  },
  created() {
    // console.log(this.curRou, 'curRou')
    this.getOption();
  },
  mounted() {
    this.noticeRun();
    if (getQueryVariable("login")) this.quickLogin();
  },
  methods: {
    toTutorials() {
      // window.open("/", "_blank");
      window.open("https://gocn.co.uk/tutorials.html");
    },
    // 获取三级联动所有值
    getOption() {
      getChinaCityList().then((res) => {
        if (
          res.data &&
          res.data.code == 0 &&
          res.data.data &&
          res.data.data.list
        ) {
          this.$store.state.cityOption = res.data.data.list;
          // this.options = res.data.data.list;
        }
      });
    },
    //投诉反馈
    addComplaintFeedback() {
      let upDate = {
        content: this.callContent.question,
        email: this.callContent.email,
        messageType: this.callSuggest,
        name: this.callContent.name,
        phone: this.callContent.tel,
      };
      addComplaintFeedback(upDate).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.message);
          (this.callContent.email = ""),
            (this.callContent.name = ""),
            (this.callContent.tel = ""),
            (this.callContent.question = "");
        } else {
          this.$message.error(res.data.message);
        }
        console.log(res, "投诉反馈接口返回值");
      });
    },

    limitInput(value, name) {
      this.indentData[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    //批量下单被点击
    bulkOrderClicked() {
      // 判断用户是否登录，没登录打开登录弹框
      if (!this.isLoginStatus()) {
        this.$store.state.dialog = true;
        return;
      }
      this.$store.state.bulkStep = 1;
      this.$router.push({
        path: `/BulkOrder`,
      });
    },
    //立即下单按钮被惦记
    getNowOnClick() {
      // 判断用户是否登录，没登录打开登录弹框
      if (!this.isLoginStatus()) {
        this.$store.state.dialog = true;
        return;
      }
      if (!this.indentData.weight) {
        this.indentCheck.weight = false;
        return;
      }
      if (!this.indentData.length) {
        this.indentCheck.length = false;
        return;
      }
      if (!this.indentData.width) {
        this.indentCheck.width = false;
        return;
      }
      if (!this.indentData.height) {
        this.indentCheck.height = false;
        return;
      }
      let mySelect = this.$refs.mySelect.selectedLabel;
      // 存储目的地
      this.$store.dispatch("updateDest", mySelect);
      this.$store.dispatch("updateItemInfo", this.indentData);
      this.$store.state.step = 1;
      this.$router.push({
        path: `/OrderNow`,
        // query:{
        //   indentData:JSON.stringify(this.indentData)
        // }
      });
    },
    //订单输入进行了修改
    // inputOninput(arg){
    //   if(arg == "weight"){
    //     // this.indentData.weight = arg.replace(/[^0-9]/g, '');
    //     this.indentCheck.weight = true;
    //   }
    //   else if(arg == "length"){
    //     // this.indentData.length = arg.replace(/[^0-9]/g, '');
    //     this.indentCheck.length = true;
    //   }
    //   else if(arg == "width"){
    //     // this.indentData.width = arg.replace(/[^0-9]/g, '');
    //     this.indentCheck.width = true;
    //   }
    //   else if(arg == "height"){
    //     // this.indentData.height = arg.replace(/[^0-9]/g, '');
    //     this.indentCheck.height = true;
    //   }
    // },
    //快捷下单的选项被点击
    quickOrderClick(item) {
      // 判断用户是否登录，没登录打开登录弹框
      if (!this.isLoginStatus()) {
        this.$store.state.dialog = true;
        return;
      }
      let obj = { ...this.indentData, ...{ width: 1, height: 1, length: 1 } };
      // 两斤奶粉
      if (item.count == 2) {
        obj.weight = 1.8;
      } else if (item.count == 4) {
        obj.weight = 4;
      } else if (item.count == 6) {
        obj.weight = 6;
      }

      let mySelect = this.$refs.mySelect.selectedLabel;
      // 存储目的地
      this.$store.dispatch("updateDest", mySelect);
      this.$store.dispatch("updateItemInfo", obj);
      this.$store.state.step = 1;
      this.$router.push({
        path: `/OrderNow`,
        // query:{
        //   indentData:JSON.stringify(this.indentData)
        // }
      });
    },
    //点击了联系我们的选择类型按钮
    changeCallType(type) {
      this.callSuggest = type;
      if (type == 1) this.isCallSugest = true;
      else this.isCallSugest = false;
    },
    //点击了小功能区的运费查询
    FreightQuery() {
      this.$router.push({
        path: "/FreightQuery",
      });
    },
    //点击了小功能区的上传身份证
    idCardUpload() {
      // this.$router.push({
      //   path: '/IdCardUpload'
      // })
      let routeUrl = this.$router.resolve({
        path: "/IdCardUpload",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 跳转常见问题页面
    openCommonProblem() {
      this.$router.push({
        path: "/commonProblem",
      });
    },
    // 跳转SKU页面
    openSku() {
      this.$router.push({
        path: "/SKU",
      });
    },
    //建议和投诉发送
    sendClicked() {
      console.log("发送按钮点击");
      this.addComplaintFeedback();
    },
    // 判断登录状态，打开登录弹框
    isLoginStatus() {
      let userInfo = this.$store.getters.getUserInfo;
      console.log(userInfo, "userInfo 首页");
      if (userInfo.username) {
        return true;
      } else {
        return false;
      }
    },
    //监听通知弹窗确认按钮（“不再提醒”）
    onPopConfirm() {
      this.setNoticeStatus(1);
    },
    //监听通知弹窗关闭按钮
    onPopCancel() {
      this.setNoticeStatusSession(1);
    },
    //获取长期通知状态 1不再提醒
    getNoticeStatus() {
      return localStorage.getItem("noticeStatus" + this.notice.id);
    },
    //设置长期通知状态 1不再提醒
    setNoticeStatus(status) {
      localStorage.setItem("noticeStatus" + this.notice.id, status);
    },
    //获取当前窗口通知状态 1关闭
    getNoticeStatusSession() {
      return sessionStorage.getItem("noticeStatusSession" + this.notice.id);
    },
    //设置当前窗口通知状态 1关闭
    setNoticeStatusSession(status) {
      sessionStorage.setItem("noticeStatusSession" + this.notice.id, status);
    },
    //开始进行弹窗通知
    async noticeRun() {
      // this.setNoticeStatus(0);
      // this.setNoticeStatusSession(0);
      if (this.getNoticeStatus() == 1) return;
      if (this.getNoticeStatusSession() == 1) return;
      let a = await getNotice();
      console.log(a);
      if (a.data.code == 0) {
        if (a.data.data.length > 0)
          this.notice = {
            title: a.data.data[0].title,
            content: a.data.data[0].content,
            id: a.data.data[0].content.id,
            open: 1,
          };
        return;
      }
      //TODO 获取通知信息
    },

    // 自动登录
    quickLogin() {
      console.log(decodeURIComponent(getQueryVariable("login")));
      let data = decodeURIComponent(getQueryVariable("login"));
      quickLogin({ loginToken: data }).then((res) => {
        if (res.data.code != 0) return;
        localStorage.setItem("token", res.data.data.verification);
        console.log("quickLogin ", res);
        console.log("res.data.data.tags ", res.data.data.tags);
        localStorage.setItem("accessTags", JSON.stringify(res.data.data.tags));
        isLogin()
          .then((resLogin) => {
            console.log("isLogin ", resLogin);
            if (resLogin.data.code == 0) {
              this.$store.dispatch("updateUserInfo", resLogin.data.userInfo);
            } else {
              this.$store.dispatch("updateUserInfo", {});
              localStorage.removeItem("token");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.dispatch("updateUserInfo", {});
            localStorage.removeItem("token");
          });
      });
    },
  },
};
</script>

<style>
/* tooltip 自定义样式 */
.my-tooltip.is-dark {
  background: #ff6400;
}
.my-tooltip[x-placement^="top"] .popper__arrow::after {
  border-top-color: #ff6400;
}
.my-tooltip[x-placement^="top"] .popper__arrow {
  border-top-color: #ff6400;
}
</style>
<style lang="less" scoped>
.home {
  //主区内容
  .home-main {
    //自定义下单
    .main-input-data {
      position: relative;
      //自定义下单内容div
      .input-data-content {
        width: 1000px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //标题
        .title {
          height: 88px;
          font-size: 63px;
          font-weight: 600;
          color: #30235c;
          line-height: 88px;
        }
        //标题广告语
        .title-dis {
          height: 42px;
          font-size: 30px;
          font-weight: 400;
          color: #b8b5c6;
          line-height: 42px;
        }
        //下单教程Icon
        .use-course {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 49px;
          cursor: pointer;
          .use-cource-text {
            height: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #ff7e57;
            line-height: 20px;
            margin-left: 6px;
          }
        }
        //下单输入操作
        .input-div {
          margin-top: 15px;
          //select 修改样式
          .input-select::v-deep .el-input .el-input__inner {
            width: 160px;
            height: 44px;
          }
          .input-select::v-deep .el-select .el-input.is-focus .el-input__inner {
            border-color: #6d5efe;
          }
          .input-select::v-deep .el-select .el-input__inner:focus {
            border-color: #6d5efe;
          }
          //出发地和发往地
          .input-select {
            display: flex;
            flex-direction: row;
            align-items: center;
            //发往
            .send-to {
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              color: #1e2b4f;
              line-height: 20px;
              margin: 0px 13px 0 20px;
            }
          }
          //input 修改样式
          .input-information::v-deep .el-input .el-input__inner {
            height: 44px;
            background: #ffffff;
          }
          .input-information::v-deep .el-input {
            width: 90px;
          }
          //物品具体数据
          .input-information {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            //单位 div
            .unit {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              margin: 0px 12px 0 6px;
            }
          }
          //下单操作
          .input-sure-btn-div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 60px;
            //立即下单
            .get-now {
              width: 140px;
              height: 50px;
              background: #6d5efe;
              border-radius: 35px;
              font-size: 22px;
              font-weight: 400;
              color: #ffffff;
              line-height: 42px;
              text-align: center;
              line-height: 50px;
            }
            //批量下单
            .more-get {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 42px;
              .content {
                height: 50px;
                font-size: 20px;
                font-weight: 400;
                color: #6d5efe;
                line-height: 50px;
                margin-right: 5px;
              }
              .content-right {
                font-size: 20px;
                font-weight: bold;
                color: #6d5efe;
                line-height: 42px;
              }
            }
          }
        }
      }
    }
    //快捷下单
    .main-quit-order {
      height: 270px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // 操作div
      .choose-order-div {
        width: 1000px;
        height: 234px;
        background: #30225b;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        // title
        .choose-orde-title {
          display: flex;
          flex-direction: column;
          margin-top: 64px;
          margin-left: 54px;
          width: 364px;
          .choose-title {
            height: 65px;
            font-size: 46px;
            font-weight: bold;
            color: #ffffff;
            line-height: 65px;
          }
          .choose-title-es {
            height: 41px;
            font-size: 29px;
            font-weight: 400;
            color: #6cc1f5;
            line-height: 41px;
          }
        }
        //快捷订单选择item div
        .order-choose {
          display: flex;
          flex-direction: row;
          padding-left: 28px;
          //badge 修改样式
          .order::v-deep .el-badge__content {
            height: 30px;
            background-color: #5abe64;
            text-align: center;
            line-height: 30px;
            border-radius: 15px;
            font-size: 14px;
            border-color: #5abe64;
          }
          .order::v-deep .el-badge__content.is-fixed {
            top: 4px;
            right: 20px;
          }
          //快捷订单item
          .order {
            width: 153px;
            height: 214px;
            border-radius: 0px 0px 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 42px;
            //订单商品内容
            .order-value {
              height: 22px;
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              line-height: 22px;
              margin-top: 20px;
            }
            //订单商品内容-英文
            .order-value-es {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #6cc1f5;
              line-height: 20px;
              margin-top: 1px;
            }
          }
          .order:hover {
            background: #6d5efe;
          }
        }
      }
    }
    //小功能区域
    .main-small-fun {
      background: #f3f4fd;
      height: 702px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // 操作区域
      .choose-fun-div {
        width: 1000px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //标题区域
        .choose-fun-title {
          margin-top: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .choose-title {
            height: 56px;
            font-size: 40px;
            font-weight: 600;
            color: #30235c;
            line-height: 56px;
            text-align: center;
          }
          .choose-title-es {
            margin-top: 20px;
            width: 617px;
          }
        }
        //操作区域
        .choose-fun {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          //操作的item
          .fun-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            width: 220px;
            height: 280px;
            background: #ffffff;
            border-radius: 30px;
            // margin-right: 60px;
            margin-top: 71px;
            //操作图标
            .fun-icon {
              width: 102px;
              margin-top: 40px;
            }
            //操作指示
            .fun-name {
              height: 42px;
              font-size: 25px;
              font-weight: bold;
              color: #6d5efe;
              line-height: 42px;
              margin-top: 40px;
            }
            //操作指示-英文
            .fun-name-es {
              height: 25px;
              font-size: 18px;
              font-weight: 400;
              color: #6cc1f5;
              line-height: 25px;
              margin-top: 8px;
            }
          }
          //操作item悬停时添加高度和阴影
          .fun-item:hover {
            box-shadow: 0px 32px 37px 0px rgba(109, 94, 254, 0.15);
            margin-top: 61px;
          }
        }
      }
    }
    //联系我们
    .main-contact-us {
      background: #ffffff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //操作区域
      .contact-us-div {
        width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //title 区域
        .contact-us-title {
          margin-top: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .contact-title {
            height: 56px;
            font-size: 40px;
            font-weight: 600;
            color: #30235c;
            line-height: 56px;
          }
          .contact-title-es {
            margin-top: 16px;
            width: 277px;
          }
        }
        //显示和输入区
        .contact-us {
          margin-top: 71px;
          width: 1117px;
          height: 727px;
          background-image: url("../assets/img/home/home_contact_box.png");
          background-size: 1117px 727px;
          display: flex;
          flex-direction: row;
          //联系方式展示区域
          .contact-show {
            margin-top: 48px;
            margin-left: 122px;
            width: 436px;
            //title
            .show-title {
              height: 61px;
              font-size: 43px;
              font-weight: 600;
              color: #ffffff;
              line-height: 61px;
              text-shadow: 0px 46px 65px #e5e2ff;
              margin-bottom: 91px;
            }
            //显示的item
            .show-item {
              display: flex;
              flex-direction: column;
              margin-bottom: 25px;
              .item-value {
                display: flex;
                flex-direction: row;
                align-items: center;
                .item-sign {
                  width: 10px;
                  height: 10px;
                  background: #ffffff;
                  border-radius: 50%;
                }
                .item-value-content {
                  height: 41px;
                  font-size: 29px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 41px;
                  text-shadow: 0px 46px 65px #e5e2ff;
                  margin-left: 24px;
                }
              }
              .item-express {
                height: 22px;
                font-size: 16px;
                font-weight: 400;
                color: #d1cefb;
                line-height: 22px;
                text-shadow: 0px 46px 65px #e5e2ff;
                margin-left: 34px;
                margin-top: 4px;
              }
            }
          }
          //操作输入区域
          .contact-input {
            margin-top: 51px;
            margin-left: 60px;
            // 标题
            .input-title {
              height: 56px;
              font-size: 40px;
              font-weight: 600;
              color: #6d5efe;
              line-height: 56px;
              text-shadow: 0px 46px 65px #e5e2ff;
            }
            // 操作类型
            .input-type {
              margin-top: 40px;
              display: flex;
              flex-direction: row;
              .input-type-item-nor {
                width: 97px;
                height: 44px;
                background: #f7f8fd;
                // box-shadow: 0px 46px 65px 0px #E5E2FF;
                border-radius: 10px;
                border: 1px solid #d1cefb;
                font-size: 14px;
                font-weight: 400;
                color: #1e2b4f;
                line-height: 44px;
                text-shadow: 0px 46px 65px #e5e2ff;
                text-align: center;
                margin-right: 20px;
              }
              .input-type-item-select {
                background: #e2e0ff;
                border: 1px solid #6d5efe;
                color: #6d5efe;
                font-weight: bold;
              }
            }
            // 单行输入的item
            .input-oneline-item {
              width: 390px;
              height: 44px;
              background: #f7f8fd;
              // box-shadow: 0px 46px 65px 0px #E5E2FF;
              border-radius: 10px;
              border: 1px solid #d1cefb;
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              .input-content-name {
                margin-left: 20px;
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #636d88;
                line-height: 20px;
                text-shadow: 0px 46px 65px #e5e2ff;
              }
            }
            //修改input-oneline-item的input修改样式
            .input-oneline-item::v-deep .el-input {
              width: 290px;
            }
            .input-oneline-item::v-deep .el-input .el-input__inner,
            .input-oneline-item::v-deep .el-input .el-input__inner:hover,
            .input-oneline-item::v-deep .el-input .el-input__inner:focus {
              background-color: #f7f8fd;
              border: 0px !important;
              font-size: 14px;
              font-weight: 400;
              color: #636d88;
              text-shadow: 0px 46px 65px #e5e2ff;
            }
            //修改input-moreline-item的input修改样式
            .input-moreline-item::v-deep .el-textarea .el-textarea__inner {
              width: 390px;
              height: 132px;
              background: #f7f8fd;
              border-radius: 10px;
              border: 1px solid #d1cefb;
              margin-top: 10px;
              font-size: 14px;
              font-weight: 400;
              color: #636d88;
              text-shadow: 0px 46px 65px #e5e2ff;
              padding: 12px 20px;
            }
            .input-moreline-item::v-deep .el-textarea .el-textarea__inner {
              &::placeholder {
                font-size: 14px;
                font-weight: 400;
                color: #636d88;
                text-shadow: 0px 46px 65px #e5e2ff;
              }
            }
            //发送按钮
            .input-send-btn {
              width: 97px;
              height: 44px;
              background: #6d5efe;
              box-shadow: 0px 46px 65px 0px #e5e2ff;
              border-radius: 22px;
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              line-height: 22px;
              text-shadow: 0px 46px 65px #e5e2ff;
              text-align: center;
              line-height: 44px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
